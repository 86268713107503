var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"visible":_vm.visible,"toggle":_vm.toggle}},[_c('div',[_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.forms.materialType.label))]),_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.forms.materialType.value),expression:"forms.materialType.value",modifiers:{"number":true}}],staticClass:"form__input w-full",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.forms.materialType, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.materialTypeOnChange]}},_vm._l((_vm.materialTypes),function(materialType){return _c('option',{key:materialType.value,attrs:{"disabled":materialType.value === 0},domProps:{"value":materialType.value}},[_vm._v(_vm._s(materialType.text))])}),0)]),(_vm.forms.materialType.value === _vm.materialTypes.transmitter.value)?_c('div',{staticClass:"mt-10"},[_c('ValidationObserver',{ref:"transmitterForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitTransmitterForm)}}},[_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"w-64 mr-6"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.transmitter.id.label,"rules":"required|numeric|max:255|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.transmitter.id.label)),_c('Required')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forms.transmitter.id.value),expression:"forms.transmitter.id.value"}],staticClass:"form__input w-full",attrs:{"type":"number","min":"1"},domProps:{"value":(_vm.forms.transmitter.id.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forms.transmitter.id, "value", $event.target.value)}}}),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-64"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.transmitter.ttnId.label,"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.transmitter.ttnId.label)),_c('Required')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forms.transmitter.ttnId.value),expression:"forms.transmitter.ttnId.value"}],staticClass:"form__input w-full",domProps:{"value":(_vm.forms.transmitter.ttnId.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forms.transmitter.ttnId, "value", $event.target.value)}}}),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"w-full mt-2"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.transmitter.type.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.transmitter.type.label)),_c('Required')],1),(_vm.transmitterTypes !== null)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.forms.transmitter.type.value),expression:"forms.transmitter.type.value",modifiers:{"number":true}}],staticClass:"form__input w-full",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.forms.transmitter.type, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.transmitterTypes),function(transmitterType){return _c('option',{key:transmitterType.id,domProps:{"value":transmitterType.id}},[_vm._v(_vm._s(_vm.$t('backend_trans_keys.' + transmitterType.transKey)))])}),0),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]):_c('div',[_c('i',{staticClass:"fas fa-spinner fa-spin"})])]}}],null,true)})],1),(_vm.forms.transmitter.type.value === _vm.transmitterTypeWeatherStation)?_c('div',[_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"w-64 mr-6"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.transmitter.pyranometer.label,"rules":"required|numeric|max:255|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.transmitter.pyranometer.label)),_c('Required')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forms.transmitter.pyranometer.value),expression:"forms.transmitter.pyranometer.value"}],staticClass:"form__input w-full",attrs:{"type":"number","min":"1"},domProps:{"value":(_vm.forms.transmitter.pyranometer.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forms.transmitter.pyranometer, "value", $event.target.value)}}}),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-64"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.transmitter.hygrometer.label,"rules":"required|numeric|max:255|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.transmitter.hygrometer.label)),_c('Required')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forms.transmitter.hygrometer.value),expression:"forms.transmitter.hygrometer.value"}],staticClass:"form__input w-full",domProps:{"value":(_vm.forms.transmitter.hygrometer.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forms.transmitter.hygrometer, "value", $event.target.value)}}}),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"w-64 mr-6"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.transmitter.barometer.label,"rules":"required|numeric|max:255|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.transmitter.barometer.label)),_c('Required')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forms.transmitter.barometer.value),expression:"forms.transmitter.barometer.value"}],staticClass:"form__input w-full",attrs:{"type":"number","min":"1"},domProps:{"value":(_vm.forms.transmitter.barometer.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forms.transmitter.barometer, "value", $event.target.value)}}}),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-64"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.transmitter.anemometer.label,"rules":"required|numeric|max:255|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.transmitter.anemometer.label)),_c('Required')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forms.transmitter.anemometer.value),expression:"forms.transmitter.anemometer.value"}],staticClass:"form__input w-full",domProps:{"value":(_vm.forms.transmitter.anemometer.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forms.transmitter.anemometer, "value", $event.target.value)}}}),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e(),(_vm.transmitterTypes !== null)?_c('button',{staticClass:"mt-6 btn btn--success"},[_c('i',{staticClass:"fas fa-check mr-2"}),_vm._v(_vm._s(_vm.$t('pages.inventory.addMaterialModal.forms.validate')))]):_vm._e()])]}}],null,false,3174581700)})],1):(_vm.forms.materialType.value === _vm.materialTypes.sensor.value)?_c('div',{staticClass:"mt-10"},[_c('ValidationObserver',{ref:"sensorForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitSensorForm)}}},[_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"w-64 mr-6"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.sensor.id.label,"rules":"required|numeric|max:255|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.sensor.id.label)),_c('Required')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forms.sensor.id.value),expression:"forms.sensor.id.value"}],staticClass:"form__input w-full",attrs:{"type":"number","min":"1"},domProps:{"value":(_vm.forms.sensor.id.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forms.sensor.id, "value", $event.target.value)}}}),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-64"},[_c('ValidationProvider',{attrs:{"name":_vm.forms.sensor.type.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.forms.sensor.type.label)),_c('Required')],1),(_vm.sensorTypes !== null)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.forms.sensor.type.value),expression:"forms.sensor.type.value",modifiers:{"number":true}}],staticClass:"form__input w-full",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.forms.sensor.type, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.sensorTypes),function(sensorType){return _c('option',{key:sensorType.id,domProps:{"value":sensorType.id}},[_vm._v(_vm._s(_vm.$t('backend_trans_keys.' + sensorType.transKey)))])}),0),_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))])]):_c('div',[_c('i',{staticClass:"fas fa-spinner fa-spin"})])]}}],null,true)})],1)]),(_vm.sensorTypes !== null)?_c('button',{staticClass:"mt-6 btn btn--success"},[_c('i',{staticClass:"fas fa-check mr-2"}),_vm._v(_vm._s(_vm.$t('pages.inventory.addMaterialModal.forms.validate')))]):_vm._e()])]}}])})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }