<template>
  <div>
    <div v-if="isTransmitterLoaded">

      <!-- Confirm modal to remove the transmitter from the culture -->
      <div v-if="transmitter.culture !== null" >
        <confirm-modal @cancel="toggleRemoveModal" @yes="removeTransmitter" :visible="removeModal.isVisible" :toggle="toggleRemoveModal">
          <p v-html="$t('pages.transmitter_show.removeModal.title', {id: transmitter.id, culture: transmitter.culture.name})"></p>
          <p class="mt-2 italic"><i class="fas fa-exclamation-triangle mr-2 text-yellow-400"></i>{{ $t('pages.transmitter_show.removeModal.info') }}</p>
        </confirm-modal>

        <button @click="toggleRemoveModal" class="mb-4 btn btn--danger"><i class="fas fa-minus-circle mr-2"></i>{{ $t('pages.transmitter_show.removeBtn') }}</button>
      </div>

      <!-- Transmitter details -->
      <div class="card">
        <h2 class="card__title">{{ $t('pages.transmitter_show.cards.transmitter.title') }} <span class="font-semibold">"{{ transmitter.id }}"</span></h2>
        <div class="flex justify-items-start">
          <div>
            <div>{{ $t('pages.transmitter_show.cards.transmitter.type') }}: </div>
            <div>{{ $t('pages.transmitter_show.cards.transmitter.culture') }}: </div>
            <div>{{ $t('pages.transmitter_show.cards.transmitter.lastActivityAt') }}: </div>
            <div>{{ $t('pages.transmitter_show.cards.transmitter.battery') }}: </div>
            <div>{{ $t('pages.transmitter_show.cards.transmitter.lastMeasureAt') }}: </div>
          </div>

          <div class="ml-4 font-semibold">
            <div>{{ $t('backend_trans_keys.'+transmitter.typeTransKey) }}</div>
            <div>{{ transmitter.culture === null ? this.$t('words.none') : transmitter.culture.name + " (" + this.$t('backend_trans_keys.'+transmitter.culture.typeTransKey) + " " + this.$t('backend_trans_keys.'+transmitter.culture.varietyTransKey) + ")" }}</div>
            <div>{{ transmitter.lastSeenAt == null ? this.$t('words.never') : this.lastSeenAt(transmitter.lastSeenAt) }}</div>
            <div>{{ batteryLevel }}</div>
            <div>{{ transmitter.lastMeasureAt == null ? this.$t('words.never') : this.lastSeenAt(transmitter.lastMeasureAt) }}</div>
          </div>
        </div>
      </div>

      <!-- Linked sensors -->
      <div class="card mt-4">
        <h2 class="card__title">{{ $t('pages.transmitter_show.cards.sensors.title', {"count": transmitter.sensors.length}) }}</h2>
        <data-table :columns="sensorsTable.columns" :data="sensorsTable.data" />
      </div>

      <!-- Charts -->
      <div v-if="isTransmitterHasCultureAndSensor">
        <div class="mt-4">
          <button v-if="selectedTimePeriod !== 5" @click="refreshCharts" class="mr-4 btn btn--primary"><i class="fas fa-sync" :class="{ 'fa-spin': loadingMeasures }"></i></button>
          <btn-with-loader class="mt-4" :loading="loadingExportCSV" :class-btn-type="'btn--primary'" @clicked="downloadCsv"><i class="fas fa-download mr-2"></i>CSV</btn-with-loader>
        </div>
        <select-time-period :default-value="selectedTimePeriod" :default-show-data-picker="showDatePicker" :default-start-date="startDate" :default-end-date="endDate" @valueChanged="timePeriodChanged" @submitCustomDate="submitCustomPeriod" class="mt-3"/>

        <div v-if="isMeasuresLoaded">
          <div v-for="measureType in measureTypes" :key="measureType.id" class="card mt-5">
            <h2 class="card__title">{{ $t('backend_trans_keys.'+measureType.typeTransKey) }}</h2>
            <div class="overflow-x-auto">
              <div style="min-width: 600px">
                <highcharts :options="measureType.chartOptions" ref="lineCharts" constructor-type="chart"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import DataTable from "../../../components/general/datatable/data-table";
import SelectTimePeriod from "../../../components/charts/SelectTimePeriod";
import {
  apiGetRequest,
  apiPostRequest,
  displayAlertError,
  displayAlertSuccess,
  displayDateTimestampByLocale, displayElapsedTimeToTimestamp,
  generateDownloadCsvLink,
  getLetterForSensor
} from "../../../utils";
import {
  API_TRANSMITTERS_MEASURES_CSVEXPORT_SUFFIX,
  API_TRANSMITTERS_MEASURES_SUFFIX,
  API_TRANSMITTERS_REMOVE_FROM_CULTURE,
  API_TRANSMITTERS_SHOW
} from "../../../utils/constants";
import {getFromAndToQueryParam, getFromQueryParam, getLineChartOptionsByMeasureType} from "../../../utils/chartUtils";
import BtnWithLoader from "../../../components/general/btn-with-loader";
import ConfirmModal from "../../../components/modals/confirm-modal";

export default {
  name: "TransmitterShow",
  components: {BtnWithLoader, DataTable, SelectTimePeriod, ConfirmModal},
  data: function() {
    return {
      id: this.$route.params.id,
      isTransmitterLoaded: false,
      transmitter : {},
      sensorsTable : {
        columns: {
          id: this.$t('words.id'),
          letter: this.$t('words.letter'),
          channel: this.$t('words.channel'),
          type: this.$t('words.type'),
          actions: this.$t('words.actions')
        },
        data: null
      },
      loadingExportCSV: false,
      selectedTimePeriod: 1,
      showDatePicker: false,
      startDate:"",
      endDate:"",
      loadingMeasures: false,
      isMeasuresLoaded: false,
      measureTypes : {},
      removeModal: {
        isVisible: false,
      }
    }
  },
  mounted() {
    this.isTransmitterLoaded = false

    apiGetRequest(API_TRANSMITTERS_SHOW + this.id)
        .then((res) => {
          this.transmitter = res.data
          this.sensorsTable.data = this.transmitter.sensors.map((s) => {
            s.letter = getLetterForSensor(s.id)
            s.type = this.$t('backend_trans_keys.'+s.typeTransKey)
            s.actions = {
              see: {
                to: {name: 'sensor_show', params:{id: s.id}},
                text: this.$t('words.see')
              }
            }
            return s
          })

          this.isTransmitterLoaded = true

          if(this.isTransmitterHasCultureAndSensor){
            this.refreshCharts()
          }
        })
  },
  computed: {
    isTransmitterHasCultureAndSensor: function(){
      return this.transmitter.culture !== null && this.transmitter.sensors.length > 0
    },
    batteryLevel: function() {
      let lastBatteryAt = this.transmitter.lastBatteryAt === null ? "" : "(" + this.displayDate(this.transmitter.lastBatteryAt) + ")"
      return this.transmitter.lastBatteryAt == null ? this.$t('words.unknown') : this.transmitter.batteryPct + " % " + lastBatteryAt
    }
  },
  methods: {
    /**
     * Asynchronous call to generate csv export that contains all measures by this transmitter
     * For ajax call, we need to recreate a link and simulate a click to download the real file.
     */
    downloadCsv: function() {
      if(!this.loadingExportCSV){
        this.loadingExportCSV = true
        apiGetRequest(API_TRANSMITTERS_SHOW + this.id + API_TRANSMITTERS_MEASURES_CSVEXPORT_SUFFIX)
            .then((res) => {
              const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
              link.click();
            })
            .finally(() => this.loadingExportCSV = false)
      }

    },

    timePeriodChanged: function(selectedTimePeriod){
      this.selectedTimePeriod = selectedTimePeriod
      if(selectedTimePeriod !== 5){
        this.refreshCharts()
        this.showDatePicker = false
      }else{
        this.showDatePicker = true
      }
    },
    submitCustomPeriod: function(rangeDates){
      this.startDate = rangeDates.start
      this.endDate = rangeDates.end
      const fromAndToQueryParams = getFromAndToQueryParam(rangeDates)
      this.fetchUpdatedData(fromAndToQueryParams)

    },

    refreshCharts: function() {
      const fromQueryParam = getFromQueryParam(this.selectedTimePeriod)
      this.fetchUpdatedData(fromQueryParam)
    },
    /**
     * Ask the API to fetch all measures according the selected time period, and display all charts.
     * We fetch each time the whole wanted data, not only the new one (if we refresh multiple time)
     * todo later : fetch only new data, according the last loaded value
     */
    fetchUpdatedData : function(queryParams){
      if(!this.loadingMeasures) {
        this.isMeasuresLoaded = false
        this.measureTypes = {}
        this.loadingMeasures = true
       apiGetRequest(API_TRANSMITTERS_SHOW + this.id + API_TRANSMITTERS_MEASURES_SUFFIX + queryParams)
            .then((res) => {
              this.updateMeasureTypes(res.data)
              this.isMeasuresLoaded = true
            })
            .finally(() => this.loadingMeasures = false)
      }
    },
    /**
     * Update (extends) the local measureTypes variable to add all measures data provided by the API.
     * The updated value will contain all data and options for the chart displaying
     * @param data All measures data provided by the API
     */
    updateMeasureTypes: function(data) {
      this.measureTypes = data

      for (const key in this.measureTypes) {
        const measureType = this.measureTypes[key]
        // Error may be possible when chart export, if we have an old browser
        measureType["chartOptions"] = getLineChartOptionsByMeasureType(measureType, () => {
          const errorMsg = this.$t('pages.sensor_show.error-export')
          displayAlertError(errorMsg)
        })
      }
    },
    /**
     * From a specific timestamp, return a string representation of the elapsed time (xx minutes ago, ...)
     * We use moment.js and the current locale() load in moment.js for the language (according the chosen locale in the app)
     * @param lastSeenAtTimestamp Timestamp value of the last seen at
     * @returns {string} A string representation of the elapsed time (generate by moment js)
     */
    lastSeenAt: function(lastSeenAtTimestamp) {
      return displayElapsedTimeToTimestamp(lastSeenAtTimestamp)
    },
    /**
     * Display date by moment.js locale
     * @param date The date to display in timestamp
     * @returns {string} A string representation of the date, well formatted by the current locale
     */
    displayDate: function(date) {
      return displayDateTimestampByLocale(date)
    },
    /**
     * Toggle the remove transmitter modal
     */
    toggleRemoveModal: function() {
      this.removeModal.isVisible = !this.removeModal.isVisible
    },
    /**
     * Call the API to remove the current transmitter from its culture. It will be free to use somewhere else again
     */
    removeTransmitter: function() {
      apiPostRequest(API_TRANSMITTERS_REMOVE_FROM_CULTURE(this.transmitter.id), {})
          .then(() => {
            const successMsg = this.$t('pages.transmitter_show.removeModal.successMsg', {id: this.transmitter.id})
            displayAlertSuccess(successMsg)
            this.$router.push({name: "culture_show", params: {id: this.transmitter.culture.id}})
          })
          .finally(() => {
            this.toggleRemoveModal()
          })
    }
  }
}
</script>

<style scoped>

</style>
