<template>
  <div>
    <!-- Alert notifications, based on VueX global storage-->
    <alert-notifications/>

    <component :is="layout">
      <router-view :key="$route.path"/> <!-- :key to be able to load multiple route with same component https://stackoverflow.com/questions/42603583/vue-js-same-component-with-different-routes -->
    </component>
  </div>
</template>

<script>
import AlertNotifications from "./components/general/alert-notifications";

export default {
  name: 'App',
  components: {AlertNotifications},
  computed: {
    layout: function() {
      // The layout depends on the global layout state that may change (see setLayout middleware)
      // Layouts have been imported globally in main.js file
      return this.$store.state.layout
    }
  }
}
</script>

<style>
</style>
