import Vue from "vue";
import Vuex from "vuex";
import jwtDecode from "jwt-decode";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    layout: 'PublicLayout',
    accessToken: window.localStorage.getItem("access-token"),
    refreshToken: window.localStorage.getItem("refresh-token"),
    user: fetchUserInfo(),
    requestError: false,
    pendingEventsCount: 0,
    // All alert notification that is currently displayed
    alertNotifications: [],
  },
  mutations: {
    setLayout(state, newLayout){
      state.layout = newLayout
    },
    setTokens(state, data){
      state.accessToken = data.access_token
      state.refreshToken = data.refresh_token
      state.user = jwtDecode(data.access_token)
      window.localStorage.setItem("access-token", data.access_token)
      window.localStorage.setItem("refresh-token", data.refresh_token)
    },
    logout(state) {
      state.user = null
      state.accessToken = null
      state.refreshToken = null
      window.localStorage.removeItem("access-token")
      window.localStorage.removeItem("refresh-token")
    },
    setRequestError(state){
      state.requestError = true
    },
    resetRequestError(state){
      state.requestError = false
    },
    setPendingEventsCount(state, count) {
      state.pendingEventsCount = count
    },
    addAlertNotification(state, {type, msg}) {
      const id = Date.now()
      state.alertNotifications.unshift({
        id: id,
        type: type,
        msg: msg
      })
      // Automatically remove the notification after 5sec
      setTimeout(() => {
        removeAlertNotification(state, id)
      }, 5000);
    },
    removeAlertNotification(state, id) {
      removeAlertNotification(state, id)
    }
  },
  getters: {
    userEmail(state) {
      return state.user === null ? null : state.user.sub
    },
    userRoles(state) {
      return state.user === null ? null : state.user.roles
    },
    userIsAdmin(state){
      return doesUserHasRole(state, 'admin')
    },
    userIsFarmer(state) {
      return doesUserHasRole(state, 'farmer')
    }
  }
})

/**
 * Decode the access-token jwt in LocalStorage and return a user object contains payload info.
 * @returns {object|null} user object if the jwt is well formatted, null otherwise
 */
function fetchUserInfo() {
  let decodedJwt = null
  try {
    decodedJwt = jwtDecode(window.localStorage.getItem("access-token"))
    // eslint-disable-next-line no-empty
  }catch(e){}
  return decodedJwt
}

function doesUserHasRole(state, role) {
  return state.user === null ? false : state.user.roles.includes(role)
}

/**
 * Remove a specific alert notification
 * @param state The global state
 * @param id The notification ID we want to remove
 */
function removeAlertNotification(state, id) {
  const index = state.alertNotifications.findIndex(notif => notif.id === id)
  if(index !== -1){
    state.alertNotifications.splice(index, 1)
  }
}

export default store
