var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"mt-10"},[_c('h1',{staticClass:"text-2xl mb-4 font-light text-center"},[_vm._v(_vm._s(_vm.$t('pages.login.title')))]),_c('div',{staticClass:"w-80 shadow-md rounded-xl bg-white"},[_c('div',{staticClass:"bg-green-500 rounded-tl-xl rounded-tr-xl h-2"}),_c('div',{staticClass:"p-5"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('fields.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('fields.email')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('fields.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('fields.password')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"input",attrs:{"id":"password","type":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]),(_vm.errorBadCred)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t('validations.bad_credentials')))]):_vm._e()]}}],null,true)})],1),_c('button',{staticClass:"btn w-full",class:{ 'btn--success': !invalid,'btn--disabled': invalid }},[_vm._v(_vm._s(_vm.$t('pages.login.sign-in')))])])]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }